import React, { useState } from "react";
import {
  Box,
  Button,
  InputAdornment,
  InputBase,
  Typography,
  Grid,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSession } from "../redux/slices/notesSlices/AuthSlice";
import { authenticateUser } from "../services/auth-utils";
import coverImage from "../assets/Images/signIn.png";

const SignIn = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
    general: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setFormErrors((prev) => ({ ...prev, [name]: "" })); // Clear specific error on input
  };

  const handleTogglePasswordVisibility = () => setShowPassword((prev) => !prev);

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    const { email, password } = formData;

    // Reset errors
    setFormErrors({ email: "", password: "", general: "" });

    // Input validation
    if (!email) {
      setFormErrors((prev) => ({ ...prev, email: "Email is required" }));
      return;
    }
    if (!validateEmail(email)) {
      setFormErrors((prev) => ({ ...prev, email: "Invalid email format" }));
      return;
    }
    if (!password) {
      setFormErrors((prev) => ({ ...prev, password: "Password is required" }));
      return;
    }

    try {
      const response = await authenticateUser(email, password);
      dispatch(
        setSession({
          isAuthenticated: true,
          token: response.token,
          userInfo: response.userInfo,
        })
      );
      navigate("/");
    } catch (error) {
      handleAuthenticationError(error);
    }
  };

  const handleAuthenticationError = (error) => {
    if (error.message?.includes("UserNotConfirmed")) {
      setFormErrors({
        general: "Your account is not confirmed. Please verify your email.",
      });
      navigate("/verify-email");
    } else if (error.message?.includes("NotAuthorizedException")) {
      setFormErrors({ password: "Incorrect email or password." });
    } else if (error.message?.includes("UserNotFoundException")) {
      setFormErrors({ email: "No account found with this email." });
    } else {
      setFormErrors({ general: "An unexpected error occurred. Please try again later." });
    }
  };

  const renderErrorMessage = (message) =>
    message && (
      <Typography color="error" variant="body2" sx={{ mt: 1 }}>
        {message}
      </Typography>
    );

  return (
    <Grid container sx={{ height: "100vh", overflow: "hidden" }}>
      <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
        <img
          src={coverImage}
          alt="Sign In Cover"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: 3,
        }}
      >
        <Box sx={{ width: "100%", maxWidth: 400 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>
            Sign In
          </Typography>
          {renderErrorMessage(formErrors.general)}
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" sx={{ fontWeight: 600, mb: 1 }}>
              Email
            </Typography>
            <InputBase
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="your.email@example.com"
              sx={{
                width: "100%",
                height: "2.7rem",
                px: 2,
                border: "1px solid",
                borderColor: formErrors.email ? "#ff1744" : "#bbbbbb",
                borderRadius: 1,
                backgroundColor: "#fff",
              }}
            />
            {renderErrorMessage(formErrors.email)}
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" sx={{ fontWeight: 600, mb: 1 }}>
              Password
            </Typography>
            <InputBase
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              type={showPassword ? "text" : "password"}
              placeholder="********"
              sx={{
                width: "100%",
                height: "2.7rem",
                px: 2,
                border: "1px solid",
                borderColor: formErrors.password ? "#ff1744" : "#bbbbbb",
                borderRadius: 1,
                backgroundColor: "#fff",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <span
                    onClick={handleTogglePasswordVisibility}
                    style={{ cursor: "pointer" }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </span>
                </InputAdornment>
              }
            />
            {renderErrorMessage(formErrors.password)}
          </Box>
          <Button
            onClick={handleSignIn}
            variant="contained"
            sx={{
              backgroundColor: "#FF6F61",
              px: 8,
              py: 1.5,
              borderRadius: 6,
              "&:hover": { backgroundColor: "#e65a50" },
            }}
          >
            Sign In
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SignIn;
