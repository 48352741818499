import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import Nav from "../components/Navbar";
import Footer from "../components/Footer";
import { getBrew, getNotes } from "../services/api-helper";
import noteSchemas from "../components/noteSchema";

const steps = ["Category", "Details", "Review & Submit"];
const formatCategory = (key) =>
  key
    .replace(/([A-Z])/g, " $1") // Add space before capital letters
    .replace(/^./, (str) => str.toUpperCase()) // Capitalize the first letter
    .replace(/\band\b/gi, "&") // Replace "and" with "&"
    .trim(); // Trim any extra spaces


const BrewNotes = () => {
  const location = useLocation();
  const brewId = new URLSearchParams(location.search).get("brewId");

  const initialNoteState = () => ({
    id: null,
    category: "",
    description: "",
    date: dayjs(), // Use dayjs to set today's date
    [brewType]: {},
  });
  const [notes, setNotes] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [brewType, setBrewType] = useState("");
  const [currentNote, setCurrentNote] = useState(initialNoteState());
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const [notesResponse, brewResponse] = await Promise.all([
          getNotes(brewId),
          getBrew(brewId),
        ]);
        setNotes(notesResponse.notes || {});
        setBrewType(brewResponse[0]?.brewType || "");
      } catch (error) {
        console.error("Error fetching notes:", error);
        setNotes({});
      }
    };
    fetchNotes();
  }, [brewId]);

  const handleModalToggle = (open) => {
    setModalOpen(open);
    if (!open) {
      setActiveStep(0);
      setCurrentNote(initialNoteState());
      setIsEditing(false);
    }
  };

  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => setActiveStep((prev) => prev - 1);

  const handleNoteSubmit = () => {
    const updatedNotes = { ...notes };
    const noteData = {
      ...currentNote,
      date: currentNote.date ? moment(currentNote.date).format("YYYY-MM-DD") : "",
    };

    if (isEditing) {
      updatedNotes[currentNote.category] = updatedNotes[currentNote.category].map((note) =>
        note.id === currentNote.id ? noteData : note
      );
    } else {
      noteData.id = new Date().getTime();
      updatedNotes[currentNote.category] = [
        ...(updatedNotes[currentNote.category] || []),
        noteData,
      ];
    }

    setNotes(updatedNotes);
    handleModalToggle(false);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Category</InputLabel>
              <Select
                value={currentNote.category}
                onChange={(e) =>
                  setCurrentNote({ ...currentNote, category: e.target.value })
                }
              >
                {Object.keys(noteSchemas[brewType.toLowerCase()] || {}).map((key) => (
                  <MenuItem key={key} value={key}>
                    {formatCategory(key)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date"
                value={currentNote.date} // Auto-populates with today's date
                onChange={(date) => setCurrentNote({ ...currentNote, date })}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </>
        );
      case 1:
        return renderFieldsForCategory();
      case 2:
        return renderReviewContent();
      default:
        return null;
    }
  };
  const renderFieldsForCategory = () => {
    const categoryKey = currentNote.category.replace(/[^a-zA-Z]/g, "").toLowerCase();
    const schema = noteSchemas[brewType.toLowerCase()]?.[categoryKey];

    if (!schema) return <Typography>No fields available for this category</Typography>;

    return Object.entries(schema).map(([field, defaultValue]) => {
      const value = currentNote[brewType + "Specific"]?.[field] || "";
      const label = formatCategory(field);

      return (
        <TextField
          key={field}
          fullWidth
          label={label}
          value={Array.isArray(defaultValue) ? value.join(", ") : value}
          onChange={(e) => handleFieldChange(e, field, defaultValue)}
          multiline={field.toLowerCase().includes("note")}
          rows={field.toLowerCase().includes("note") ? 4 : 1}
          helperText={Array.isArray(defaultValue) && "Enter multiple values separated by commas"}
          sx={{ mb: 2 }}
        />
      );
    });
  };

  const handleFieldChange = (e, field, defaultValue) => {
    const value = Array.isArray(defaultValue)
      ? e.target.value.split(",").map((item) => item.trim())
      : e.target.value;
    setCurrentNote({
      ...currentNote,
      [brewType + "Specific"]: { ...currentNote[brewType + "Specific"], [field]: value },
    });
  };

  const renderReviewContent = () => {
    const categoryKey = currentNote.category.replace(/[^a-zA-Z]/g, "").toLowerCase();
    const schema = noteSchemas[brewType.toLowerCase()]?.[categoryKey];
    const specificData = currentNote[brewType + "Specific"] || {};

    return Object.entries(schema || {}).map(([field, _]) => (
      <Typography key={field} variant="body2">
        <strong>{formatCategory(field)}:</strong>{" "}
        {Array.isArray(specificData[field]) ? specificData[field].join(", ") : specificData[field] || "Not specified"}
      </Typography>
    ));
  };

  return (
    <>
      <Nav />
      <Grid container spacing={4} sx={{ p: 4 }}>
        {Object.keys(notes).length === 0 ? (
          <Grid item xs={12} textAlign="center">
            <Typography variant="h5">Add a note to start tracking your brew journey!</Typography>
          </Grid>
        ) : (
          Object.entries(notes).map(([category, notesArray]) =>
            notesArray.map((note) => (
              <Box key={note.id} sx={{ mb: 2, p: 2, backgroundColor: "#fff", borderRadius: 2 }}>
                <Typography>{note.description}</Typography>
                <Typography color="textSecondary">{moment(note.date).format("MMMM DD, YYYY")}</Typography>
              </Box>
            ))
          )
        )}
        <Grid item xs={12} textAlign="center">
          <Button variant="contained" startIcon={<AddIcon />} onClick={() => handleModalToggle(true)}>
            Add Note
          </Button>
        </Grid>
      </Grid>
      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={modalOpen} onClose={() => handleModalToggle(false)}
      >
        <Box
          sx={{
            p: 4,
            width: { xs: "90%", md: "50%" },
            margin: "auto",
            backgroundColor: "#fff",
            borderRadius: 2,
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <Typography variant="h6">{isEditing ? "Edit Note" : "Add Note"}</Typography>
          <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {renderStepContent(activeStep)}
          <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
            <Button disabled={activeStep === 0} onClick={handleBack}>
              Back
            </Button>
            <Button color="error" onClick={() => handleModalToggle(false)}>
              Cancel
            </Button>
            {activeStep === steps.length - 1 ? (
              <Button variant="contained" onClick={handleNoteSubmit}>
                Submit
              </Button>
            ) : (
              <Button variant="contained" onClick={handleNext}>
                Next
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
      <Footer />
    </>
  );
};

export default BrewNotes;
