import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import Reducers from "./slices/notesSlices";
import { UserAuth } from "./apis/UserAuth";
import authReducer from "./slices/notesSlices/AuthSlice"; // Add this import

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["Auth"],
};

const rootReducer = combineReducers({
  Auth: authReducer, // Add this explicitly
  ...Reducers,
  [UserAuth.reducerPath]: UserAuth.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(UserAuth.middleware),
});

export const persistor = persistStore(store);