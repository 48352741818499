import {
  Box,
  Button,
  InputAdornment,
  InputBase,
  Typography,
  Grid,
} from "@mui/material";
import React, { useState } from "react";
import coverImage from "../assets/Images/signUp.png";
// import faceBookIcon from "../assets/Images/facebook.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import GoogleIcon from "@mui/icons-material/Google";
import { useNavigate } from "react-router-dom";
import { useSignUpUserMutation } from "../redux/apis/UserAuth";

const SignUp = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isEmail, setEmail] = useState("");
  const [isPassword, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [formError, setFormError] = useState("");
  const [signupSuccess, setSignupSuccess] = useState(false);

  const navigation = useNavigate();

  const [signUpUser] = useSignUpUserMutation();

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    setEmailError("");
    setPasswordError("");
    setFirstNameError("");
    setLastNameError("");
    setFormError("");

    if (!firstName || !lastName || !isEmail || !isPassword) {
      if (!firstName) setFirstNameError("First name is required.");
      if (!lastName) setLastNameError("Last name is required.");
      if (!isEmail) setEmailError("Email is required.");
      if (!isPassword) setPasswordError("Password is required.");
      return;
    }

    if (!validateEmail(isEmail)) {
      setEmailError("Invalid email format.");
      return;
    }

    if (!validatePassword(isPassword)) {
      setPasswordError(
        "Password must be at least 8 characters, include an uppercase letter, a number, and a special character."
      );
      return;
    }

    const payload = {
      email: isEmail,
      password: isPassword,
      attributes: {
        email: isEmail,
        given_name: firstName,
        family_name: lastName,
      },
    };

    try {
      const response = await signUpUser(payload).unwrap();
      setSignupSuccess(true);
      navigation("/SignUpSuccess");
    } catch (err) {
      console.error("Error during sign-up:", err);

      if (err?.message?.includes("UsernameExistsException")) {
        setFormError("An account with this email already exists.");
      } else if (err?.message?.includes("InvalidPasswordException")) {
        setPasswordError(
          "Invalid password format. Ensure it meets the required criteria."
        );
      } else if (err?.message?.includes("InvalidParameterException")) {
        setFormError("Invalid parameters. Please check your input and try again.");
      } else if (err?.message?.includes("TooManyRequestsException")) {
        setFormError(
          "Too many requests. Please wait and try again after some time."
        );
      } else if (err?.message?.includes("LimitExceededException")) {
        setFormError(
          "Sign-up limit exceeded. Please try again later or contact support."
        );
      } else {
        setFormError("An unexpected error occurred. Please try again later.");
      }
    }
  };

  return (
    <Grid
      container
      sx={{
        height: "100vh",
        overflow: { xs: "auto", sm: "auto", md: "hidden" },
      }}
    >
      <Grid
        item={true}
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <img
          src={coverImage}
          alt="waiting..."
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: 2,
          height: "100%",
          overflowY: "auto",
          position: "relative",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            width: "100%",
            maxWidth: "400px",
          }}
        >
          <Typography variant="h5" sx={{ mb: 2, fontWeight: "600" }}>
            Sign Up
          </Typography>

          {formError && (
            <Typography color="error" variant="body2">
              {formError}
            </Typography>
          )}

          <Box>
            <Typography variant="body2" sx={{ fontWeight: "600", mb: 1 }}>
              First Name
            </Typography>
            <InputBase
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
              placeholder="First Name"
              sx={{
                width: "100%",
                height: "2.7rem",
                padding: "0 8px",
                borderColor: firstNameError ? "#ff1744" : "#bbbbbb",
                borderWidth: "1px",
                borderStyle: "solid",
                borderRadius: "10px",
                backgroundColor: "#fff",
              }}
            />
            {firstNameError && (
              <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                {firstNameError}
              </Typography>
            )}
          </Box>

          <Box>
            <Typography variant="body2" sx={{ fontWeight: "600", mb: 1 }}>
              Last Name
            </Typography>
            <InputBase
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              placeholder="Last Name"
              sx={{
                width: "100%",
                height: "2.7rem",
                padding: "0 8px",
                borderColor: lastNameError ? "#ff1744" : "#bbbbbb",
                borderWidth: "1px",
                borderStyle: "solid",
                borderRadius: "10px",
                backgroundColor: "#fff",
              }}
            />
            {lastNameError && (
              <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                {lastNameError}
              </Typography>
            )}
          </Box>

          <Box>
            <Typography variant="body2" sx={{ fontWeight: "600", mb: 1 }}>
              Your Email
            </Typography>
            <InputBase
              onChange={(e) => setEmail(e.target.value)}
              value={isEmail}
              placeholder="your.email@example.com"
              sx={{
                width: "100%",
                height: "2.7rem",
                padding: "0 8px",
                borderColor: emailError ? "#ff1744" : "#bbbbbb",
                borderWidth: "1px",
                borderStyle: "solid",
                borderRadius: "10px",
                backgroundColor: "#fff",
              }}
            />
            {emailError && (
              <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                {emailError}
              </Typography>
            )}
          </Box>

          <Box>
            <Typography variant="body2" sx={{ fontWeight: "600", mb: 1 }}>
              Your Password
            </Typography>
            <InputBase
              onChange={(e) => setPassword(e.target.value)}
              value={isPassword}
              type={showPassword ? "text" : "password"}
              placeholder="********"
              sx={{
                width: "100%",
                height: "2.7rem",
                padding: "0 8px",
                borderColor: passwordError ? "#ff1744" : "#bbbbbb",
                borderWidth: "1px",
                borderStyle: "solid",
                borderRadius: "10px",
                backgroundColor: "#fff",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <span
                    onClick={handleTogglePasswordVisibility}
                    style={{ cursor: "pointer" }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </span>
                </InputAdornment>
              }
            />
            {passwordError && (
              <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                {passwordError}
              </Typography>
            )}
          </Box>

          <Button
            onClick={handleSignUp}
            variant="contained"
            sx={{
              backgroundColor: "#FF6F61",
              px: 8,
              py: 1.5,
              borderRadius: 6,
            }}
          >
            Sign Up
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SignUp;