import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import Nav from "../components/Navbar";
import Footer from "../components/Footer";
import { getBrew, getNotes } from "../services/api-helper";

const steps = ["Category", "Details", "Review & Submit"];

const BrewNotes = () => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const brewId = queryParams.get("brewId");
  const [notes, setNotes] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [brewType, setBrewType] = useState("");
  const [currentNote, setCurrentNote] = useState({
    id: null,
    category: "",
    description: "",
    date: null,
    batchInfo: {
      name: "",
      number: "",
      dateStarted: "",
      volume: "",
      styleOrProfile: "",
      intendedABV: "",
      recipeSource: "",
    },
    preFermentation: {
      primaryIngredients: [],
      initialGravity: "",
      pH: "",
      TA: "",
      equipment: "",
      sanitationNotes: "",
    },
    meadSpecific: {
      honeyVariety: "",
      honeyCharacteristics: "",
      nutrientSchedule: [],
      adjuncts: [],
    },
    wineSpecific: {
      grapeVariety: "",
      origin: "",
      mustComposition: "",
      additives: [],
      crushDestemDetails: "",
    },
    fermentationAndAgingLogs: [],
    finalTasting: {
      finalGravity: "",
      finalABV: "",
      clarityAppearance: "",
      filtrationFining: "",
      bottlingDate: "",
      primingInfo: "",
      tastingNotes: {
        aroma: "",
        flavor: "",
        mouthfeel: "",
        finish: "",
      },
      agingPlan: "",
      overallImpressions: "",
      improvementsNextTime: "",
    },
  });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const response = await getNotes(brewId);
        setNotes(response.notes || {});
        const brewResponse = await getBrew(brewId);
        setBrewType(brewResponse[0].brewType || "");
      } catch (error) {
        console.error("Error fetching notes:", error);
        setNotes({});
      }
    };
    fetchNotes();
  }, []);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => {
    setModalOpen(false);
    setActiveStep(0);
    setCurrentNote({
      id: null,
      category: "",
      description: "",
      date: null,
      batchInfo: {
        name: "",
        number: "",
        dateStarted: "",
        volume: "",
        styleOrProfile: "",
        intendedABV: "",
        recipeSource: "",
      },
      preFermentation: {
        primaryIngredients: [],
        initialGravity: "",
        pH: "",
        TA: "",
        equipment: "",
        sanitationNotes: "",
      },
      meadSpecific: {
        honeyVariety: "",
        honeyCharacteristics: "",
        nutrientSchedule: [],
        adjuncts: [],
      },
      wineSpecific: {
        grapeVariety: "",
        origin: "",
        mustComposition: "",
        additives: [],
        crushDestemDetails: "",
      },
      fermentationAndAgingLogs: [],
      finalTasting: {
        finalGravity: "",
        finalABV: "",
        clarityAppearance: "",
        filtrationFining: "",
        bottlingDate: "",
        primingInfo: "",
        tastingNotes: {
          aroma: "",
          flavor: "",
          mouthfeel: "",
          finish: "",
        },
        agingPlan: "",
        overallImpressions: "",
        improvementsNextTime: "",
      },
    });
    setIsEditing(false);
  };

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  const handleNoteSubmit = () => {
    const updatedNotes = { ...notes };
    const noteData = {
      ...currentNote,
      date: currentNote.date ? moment(currentNote.date).format("YYYY-MM-DD") : "",
    };

    console.log("Note Data", noteData);

    if (isEditing) {
      updatedNotes[currentNote.category] = updatedNotes[currentNote.category].map((note) =>
        note.id === currentNote.id ? noteData : note
      );
    } else {
      noteData.id = new Date().getTime(); // Generate unique ID
      updatedNotes[currentNote.category] = updatedNotes[currentNote.category] || [];
      updatedNotes[currentNote.category].push(noteData);
    }

    setNotes(updatedNotes);
    handleModalClose();
  };

  const handleEditNote = (note) => {
    setCurrentNote(note);
    setIsEditing(true);
    setModalOpen(true);
  };

  const renderNotes = (notesArray) => {
    if (!Array.isArray(notesArray) || notesArray.length === 0) {
      return null;
    }

    return notesArray.map((note) => (
      <Box
        key={note.id}
        sx={{
          mb: 2,
          p: 2,
          backgroundColor: "#fff",
          borderRadius: 2,
          boxShadow: 1,
        }}
      >
        <Typography variant="body1" sx={{ mb: 1 }}>
          {note.description}
        </Typography>
        <Typography variant="body2" sx={{ color: "gray", mb: 1 }}>
          {moment(note.date).format("MMMM DD, YYYY")}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button size="small" onClick={() => handleEditNote(note)}>
            Edit
          </Button>
        </Box>
      </Box>
    ));
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Category</InputLabel>
              <Select
                value={currentNote.category}
                onChange={(e) =>
                  setCurrentNote({ ...currentNote, category: e.target.value })
                }
              >
                <MenuItem value="Tasting">Tasting</MenuItem>
                <MenuItem value="Brewing">Brewing</MenuItem>
                <MenuItem value="Fermentation">Fermentation</MenuItem>
                <MenuItem value="Aging">Aging</MenuItem>
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date"
                value={currentNote.date}
                onChange={(newDate) =>
                  setCurrentNote({
                    ...currentNote,
                    date: newDate,
                  })
                }
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </>
        );
      case 1:
        return (
          <>
            {brewType === "mead" && (
              <>
                <TextField
                  fullWidth
                  label="Honey Variety"
                  value={currentNote.meadSpecific.honeyVariety}
                  onChange={(e) =>
                    setCurrentNote({
                      ...currentNote,
                      meadSpecific: {
                        ...currentNote.meadSpecific,
                        honeyVariety: e.target.value,
                      },
                    })
                  }
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Honey Characteristics"
                  value={currentNote.meadSpecific.honeyCharacteristics}
                  onChange={(e) =>
                    setCurrentNote({
                      ...currentNote,
                      meadSpecific: {
                        ...currentNote.meadSpecific,
                        honeyCharacteristics: e.target.value,
                      },
                    })
                  }
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Nutrient Schedule"
                  value={currentNote.meadSpecific.nutrientSchedule.join(", ")}
                  onChange={(e) =>
                    setCurrentNote({
                      ...currentNote,
                      meadSpecific: {
                        ...currentNote.meadSpecific,
                        nutrientSchedule: e.target.value.split(",").map((item) => item.trim()),
                      },
                    })
                  }
                  sx={{ mb: 2 }}
                />
              </>
            )}
            {brewType === "wine" && (
              <>
                <TextField
                  fullWidth
                  label="Grape Variety"
                  value={currentNote.wineSpecific.grapeVariety}
                  onChange={(e) =>
                    setCurrentNote({
                      ...currentNote,
                      wineSpecific: {
                        ...currentNote.wineSpecific,
                        grapeVariety: e.target.value,
                      },
                    })
                  }
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Origin"
                  value={currentNote.wineSpecific.origin}
                  onChange={(e) =>
                    setCurrentNote({
                      ...currentNote,
                      wineSpecific: {
                        ...currentNote.wineSpecific,
                        origin: e.target.value,
                      },
                    })
                  }
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Must Composition"
                  value={currentNote.wineSpecific.mustComposition}
                  onChange={(e) =>
                    setCurrentNote({
                      ...currentNote,
                      wineSpecific: {
                        ...currentNote.wineSpecific,
                        mustComposition: e.target.value,
                      },
                    })
                  }
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Additives"
                  value={currentNote.wineSpecific.additives.join(", ")}
                  onChange={(e) =>
                    setCurrentNote({
                      ...currentNote,
                      wineSpecific: {
                        ...currentNote.wineSpecific,
                        additives: e.target.value.split(",").map((item) => item.trim()),
                      },
                    })
                  }
                  sx={{ mb: 2 }}
                />
              </>
            )}
            <TextField
              fullWidth
              label="Description"
              multiline
              rows={4}
              value={currentNote.description}
              onChange={(e) =>
                setCurrentNote({
                  ...currentNote,
                  description: e.target.value,
                })
              }
            />
          </>
        );
      case 2:
        return (
          <Box>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Please review your note before submitting:
            </Typography>
            <Typography variant="body2">
              <strong>Category:</strong> {currentNote.category}
            </Typography>
            <Typography variant="body2">
              <strong>Date:</strong> {moment(currentNote.date).format("MMMM DD, YYYY")}
            </Typography>
            <Typography variant="body2">
              <strong>Description:</strong> {currentNote.description}
            </Typography>
            {brewType === "mead" && (
              <>
                <Typography variant="body2">
                  <strong>Honey Variety:</strong> {currentNote.meadSpecific.honeyVariety}
                </Typography>
                <Typography variant="body2">
                  <strong>Honey Characteristics:</strong>{" "}
                  {currentNote.meadSpecific.honeyCharacteristics}
                </Typography>
                <Typography variant="body2">
                  <strong>Nutrient Schedule:</strong>{" "}
                  {currentNote.meadSpecific.nutrientSchedule.join(", ")}
                </Typography>
              </>
            )}
            {brewType === "wine" && (
              <>
                <Typography variant="body2">
                  <strong>Grape Variety:</strong> {currentNote.wineSpecific.grapeVariety}
                </Typography>
                <Typography variant="body2">
                  <strong>Origin:</strong> {currentNote.wineSpecific.origin}
                </Typography>
                <Typography variant="body2">
                  <strong>Must Composition:</strong> {currentNote.wineSpecific.mustComposition}
                </Typography>
                <Typography variant="body2">
                  <strong>Additives:</strong> {currentNote.wineSpecific.additives.join(", ")}
                </Typography>
              </>
            )}
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Nav />
      <Grid container spacing={4} sx={{ p: 4 }}>
        {Object.keys(notes).length === 0 ? (
          <Grid item xs={12} sx={{ textAlign: "center", mt: 4 }}>
            <Typography variant="h5" color="textPrimary">
              Add a note to start tracking your brew journey!
            </Typography>
          </Grid>
        ) : (
          Object.keys(notes).map((category) => renderNotes(notes[category]))
        )}
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Button variant="contained"
            startIcon={<AddIcon />}
            sx={{ backgroundColor: "#031D2D" }} onClick={handleModalOpen}>
            Add Note
          </Button>
        </Grid>
        <Modal open={modalOpen} onClose={handleModalClose}>
          <Box
            sx={{
              p: 4,
              width: "50%",
              margin: "auto",
              backgroundColor: "#fff",
              borderRadius: 2,
              mt: "10%",
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              {isEditing ? "Edit Note" : "Add Note"}
            </Typography>
            <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {renderStepContent(activeStep)}
            <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
              <Button disabled={activeStep === 0} onClick={handleBack}>
                Back
              </Button>
              <Button onClick={handleModalClose} sx={{ ml: 2 }} color="error">
                Cancel
              </Button>
              {activeStep === steps.length - 1 ? (
                <Button variant="contained" onClick={handleNoteSubmit}>
                  Submit
                </Button>
              ) : (
                <Button variant="contained" onClick={handleNext}>
                  Next
                </Button>
              )}
            </Box>
          </Box>
        </Modal>
      </Grid>
      <Footer />
    </>
  );
};

export default BrewNotes;
