import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Nav from "../components/Navbar";
import Footer from "../components/Footer";
import leave_01 from "../assets/Images/leave_01.png";
import leave_02 from "../assets/Images/leave_02.png";
import leave_03 from "../assets/Images/leave_03.png";
import leave_04 from "../assets/Images/leave_04.png";
import "../styles/home.css";

const Home = () => {
  const [videoError, setVideoError] = useState(false);

  const features = [
    {
      title: "Beginner-Friendly & Advanced-Pro Approved",
      description: "Perfect for newcomers and seasoned homebrewers alike.",
    },
    {
      title: "All-in-One Brew Journal",
      description:
        "Track recipes, tasting notes, and photos, all in one place—no coding required.",
    },
    {
      title: "Brewery Page for All",
      description:
        "Share a sleek, personal page of your top creations with friends or future customers.",
    },
    {
      title: "Free Brew Calculator",
      description:
        "Take the guesswork out of efficiency, ABV, and more with built-in calculations.",
    },
    {
      title: "Grow with Confidence",
      description:
        "From your first kit to launching a microbrewery, MyBrewPage scales as you do.",
    },
  ];

  return (
    <Box sx={{ backgroundColor: "#F8F9FA" }}>
      <Nav />

      {/* Hero Section */}
      <Grid
        container
        spacing={2}
        sx={{ px: { xs: 3, sm: 3, md: 15 }, py: 5 }}
      >
        <Grid item xs={12} md={6}>
          <Box>
            <Typography
              variant="h2"
              sx={{ fontSize: { xs: 30, sm: 35, md: 50, lg: 70 } }}
            >
              Manage Your
            </Typography>
            <Typography
              variant="h2"
              component="h3"
              sx={{ fontSize: { xs: 30, sm: 35, md: 50, lg: 70 } }}
            >
              <Box component="span" sx={{ color: "#FF6F61", mr: 1 }}>
                Brews
              </Box>
              With Ease.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography
            sx={{
              fontSize: { xs: "1.2rem", sm: "1.4rem", md: "1.6rem" },
              lineHeight: 1.4,
              fontWeight: 500,
              color: "#343A40",
              px: { xs: 2, sm: 3, md: 4 },
              my: 2,
            }}
          >
            Turn your passion into a portfolio. MyBrewPage organizes every step
            of your brewing journey and gives you a stage to show off the
            flavors you’re perfecting.
          </Typography>
        </Grid>
      </Grid>

      {/* Video & Features Section */}
      <Grid container spacing={2} sx={{ px: { xs: 3, sm: 3, md: 15 }, py: 5 }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            position: "relative",
            height: { xs: "250px", sm: "300px", md: "auto" },
            overflow: "hidden",
          }}
        >
          <video
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            autoPlay
            loop
            muted
            onError={() => setVideoError(true)}
          >
            <source
              src="https://mybrewpageaws.s3.us-east-1.amazonaws.com/brewVideo.mp4"
              type="video/mp4"
            />
            {videoError ? "Error loading video" : "Your browser does not support the video tag."}
          </video>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            p: { xs: 2, md: 4 },
          }}
        >
          <List sx={{ p: 0 }}>
            {features.map((feature, index) => (
              <ListItem key={index} sx={{ alignItems: "flex-start", p: 0, mb: 1 }}>
                <ListItemIcon sx={{ minWidth: "40px" }}>
                  <CheckCircleIcon sx={{ color: "#FF6F61" }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="body1"
                      sx={{ fontSize: { xs: "1rem", sm: "1.1rem", md: "1.2rem" } }}
                    >
                      <strong>{feature.title}: </strong>
                      {feature.description}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>

      {/* Email Section */}
      <Box
        sx={{
          width: "100%",
          height: "30rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        {[leave_01, leave_02, leave_03, leave_04].map((src, index) => (
          <img
            key={index}
            src={src}
            alt={`Decoration ${index + 1}`}
            className={`responsive-image img-${index + 1}`}
          />
        ))}
      </Box>

      {/* Footer */}
      <Box sx={{ px: { xs: 3 } }}>
        <Footer />
      </Box>
    </Box>
  );
};

export default Home;
