import { Box, Typography, Grid } from "@mui/material";
import React from "react";
import brewLogo from "../assets/Images/breLogoBlack.png";

const Footer = () => {
  const footerLinks = {
    quickLinks: ["About Us", "Blog", "Support", "Contact Us"],
    followUs: ["Facebook", "Instagram", "YouTube"],
    contact: {
      email: "support@mybrewpage.com",
      phone: "+1(123) 550-1234",
    },
  };

  return (
    <Box
      component="footer"
      sx={{
        padding: "2rem 0",
        paddingLeft: "2rem",
        paddingRight: "2rem",
        backgroundColor: "#F8F9FA",
      }}
    >
      <Grid container spacing={4} justifyContent="space-evenly">
        {/* Logo and Description */}
        <Grid item xs={12} sm={6} md={3}>
          <img
            src={brewLogo}
            alt="MyBrewPage Logo"
            style={{
              maxWidth: "150px",
              width: "100%",
              height: "auto",
              objectFit: "contain",
              marginBottom: "1rem",
            }}
          />
          <Typography
            variant="body2"
            sx={{
              color: "#343A40",
              fontSize: "0.875rem",
              lineHeight: "1.5",
            }}
          >
            Join a community of passionate brewers where every brew tells a
            story. At My Brew Page, we make it easy to document, share, and
            connect with fellow enthusiasts.
          </Typography>
          <Typography
            variant="body2"
            sx={{
              marginTop: "1.5rem",
              color: "#343A40",
              fontSize: "0.75rem",
              textAlign: "center",
            }}
          >
            © MyBrewPage
          </Typography>
        </Grid>

        {/* Quick Links */}
        <Grid item xs={12} sm={6} md={2}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 600,
              whiteSpace: "nowrap",
            }}
          >
            Quick Links
          </Typography>
          {footerLinks.quickLinks.map((link, index) => (
            <Typography
              key={index}
              variant="body2"
              sx={{
                fontSize: "0.875rem",
                marginTop: "0.5rem",
                color: "#343A40",
                whiteSpace: "nowrap",
              }}
            >
              {link}
            </Typography>
          ))}
        </Grid>

        {/* Follow Us
        <Grid item xs={12} sm={6} md={2}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 600,
              whiteSpace: "nowrap",
            }}
          >
            Follow Us
          </Typography>
          {footerLinks.followUs.map((platform, index) => (
            <Typography
              key={index}
              variant="body2"
              sx={{
                fontSize: "0.875rem",
                marginTop: "0.5rem",
                color: "#343A40",
                whiteSpace: "nowrap",
              }}
            >
              {platform}
            </Typography>
          ))}
        </Grid> */}

        {/* Contact Us */}
        <Grid item xs={12} sm={6} md={3}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 600,
              whiteSpace: "nowrap",
            }}
          >
            Contact Us
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: "0.875rem",
              marginTop: "0.5rem",
              color: "#343A40",
              whiteSpace: "nowrap",
            }}
          >
            {footerLinks.contact.email}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: "0.875rem",
              marginTop: "0.5rem",
              color: "#343A40",
              whiteSpace: "nowrap",
            }}
          >
            {footerLinks.contact.phone}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
