import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  token: undefined,
  userInfo: undefined,
};

const authSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    setSession: (state, action) => {
      state.isAuthenticated = action.payload.isAuthenticated;
      state.token = action.payload.token;
      state.userInfo = action.payload.userInfo;
    },
    clearSession: (state) => {
      state.isAuthenticated = false;
      state.token = undefined;
      state.userInfo = undefined;
    },
    initializeSession: (state) => {
      const storedSession = localStorage.getItem("userSession");
      if (storedSession) {
        const { token, userInfo } = JSON.parse(storedSession);
        state.isAuthenticated = !!token;
        state.token = token;
        state.userInfo = userInfo;
      }
    },
  },
});

export const { setSession, clearSession, initializeSession } = authSlice.actions;
export default authSlice.reducer;
