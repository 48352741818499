import { combineReducers } from "@reduxjs/toolkit";
import AuthSlice from "./AuthSlice";
import tastingSlice from "./tastingSlice";
import { UserAuth } from "../../apis/UserAuth";
const Reducers = combineReducers({
  Auth: AuthSlice,
  notes: tastingSlice,
  [UserAuth.reducerPath]: UserAuth.reducer,
});
export default Reducers;
