import React from "react";
import { Box, Button, Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import successImage from '../assets/Images/check.svg'

const SignUpSuccess = () => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      sx={{
        height: "100vh",
        overflow: { xs: "auto", sm: "auto", md: "hidden" },
      }}
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <img
          src={successImage}
          alt="Success"
          style={{
            width: "60%",
            height: "60%",
            objectFit: "contain",
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: 2,
          height: "100%",
          overflowY: "auto",
          position: "relative",
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: "600", mb: 3 }}>
          Sign-Up Successful!
        </Typography>
        <Typography
          variant="body1"
          sx={{ textAlign: "center", color: "gray", mb: 4 }}
        >
          Thank you for signing up! Please verify your email to complete the
          registration process.
        </Typography>
        <Button
          onClick={() => navigate("/SignIn")}
          variant="contained"
          sx={{
            backgroundColor: "#FF6F61",
            px: 4,
            py: 1.5,
            borderRadius: 6,
            fontSize: "1rem",
          }}
        >
          Go to Sign In
        </Button>
      </Grid>
    </Grid>
  );
};

export default SignUpSuccess;
