// noteSchemas.js

const noteSchemas = {
    beer: {
        /**
         * STREAMLINED: minimal fields for quick-entry
         */
        streamlined: {
            recipeandpreparation: {
                beerStyle: "",
                grainBill: "",
                waterVolumeProfile: "",
                originalGravity: "", // Combining pre-boil gravity or OG
            },
            primaryfermentation: {
                yeastStrain: "",
                fermentationTempControl: "",
                gravityReadings: "",
            },
            secondaryfermentation: {
                // Some brewers skip secondary, so let's keep minimal
                secondaryDuration: "",
            },
            packaging: {
                packagingDate: "",
                finalGravityFg: "",
            },
            tastingandfinalnotes: {
                appearance: "",
                aroma: "",
                flavorBalance: "",
            },
        },

        /**
         * ADVANCED: the original schema with all details
         */
        advanced: {
            recipeandpreparation: {
                beerStyle: "",
                grainBill: "",
                adjuncts: "",
                hopsSchedule: "",
                waterVolumeProfile: "",
                mashSchedule: "",
                preBoilGravityOrOG: "",
                boilDetails: "",
                recipeNotes: "",
            },
            primaryfermentation: {
                yeastStrainPitchRate: "",
                pitchTemperature: "",
                fermentationTempControl: "",
                gravityReadings: "",
                pHReadings: "",
                primaryFermentationDuration: "",
                fermenterType: "",
                offGasObservations: "",
            },
            secondaryfermentation: {
                secondaryVessel: "",
                dryHoppingDetails: "",
                clarifyingAgents: "",
                temperatureOrColdCrash: "",
                secondaryDuration: "",
                secondaryGravityPhChecks: "",
            },
            packaging: {
                packagingDate: "",
                primingSugarOrCo2VolTarget: "",
                bottlingMethod: "",
                sanitationNotes: "",
                finalGravityFg: "",
                estimatedAbv: "",
                packagingNotes: "",
            },
            tastingandfinalnotes: {
                appearance: "",
                aroma: "",
                flavorBalance: "",
                mouthfeel: "",
                overallImpression: "",
                agingNotes: "",
            },
        },
    },

    wine: {
        streamlined: {
            recipeandpreparation: {
                grapeVariety: "",
                mustComposition: "",
                sugarBrixOrPotentialAlcohol: "",
            },
            primaryfermentation: {
                yeastStrain: "",
                fermentationDuration: "",
                gravityBrixTracking: "",
            },
            secondaryfermentation: {
                malolacticFermentation: "",
            },
            packaging: {
                bottlingDate: "",
                finalAdjustments: "",
            },
            tastingandfinalnotes: {
                appearance: "",
                aroma: "",
                flavorBalance: "",
            },
        },

        advanced: {
            recipeandpreparation: {
                grapeVariety: "",
                fruitPreparation: "",
                mustComposition: "",
                sugarBrixPotentialAlcohol: "",
                acidAdjustments: "",
                initialPhTA: "",
                waterAdditions: "",
                nutrientAdditions: "",
                sanitationEquipmentPrep: "",
                additives: [], // array
            },
            primaryfermentation: {
                yeastStrain: "",
                mustTemperature: "",
                fermentationDuration: "",
                capManagement: "",
                gravityBrixTracking: "",
                pHMonitoring: "",
                additionsDuringFermentation: "",
            },
            secondaryfermentation: {
                malolacticFermentation: "",
                oakAging: "",
                sulfiteLevels: "",
                rackingSchedules: "",
                clarification: "",
                bulkAgingDuration: "",
            },
            packaging: {
                bottlingDate: "",
                stabilization: "",
                bottlingMethod: "",
                finalAdjustments: "",
                labelingNotes: "",
            },
            tastingandfinalnotes: {
                appearance: "",
                aroma: "",
                flavorBalance: "",
                mouthfeel: "",
                overallImpression: "",
                longTermAgingNotes: "",
            },
        },
    },

    mead: {
        streamlined: {
            recipeandpreparation: {
                honeyType: "",
                waterVolume: "",
                originalGravity: "",
                yeast: "",
            },
            primaryfermentation: {
                gravityCheck: "",
                yeastNutrientAdditions: "",
            },
            secondaryfermentation: {
                gravityCheck: "",
                rackingOffLees: "",
            },
            packaging: {
                bottlingDate: "",
                finalGravity: "",
            },
            tastingandfinalnotes: {
                appearance: "",
                aroma: "",
                flavorBalance: "",
            },
        },

        advanced: {
            recipeandpreparation: {
                honeyTypeQuantity: "",
                waterVolumeSource: "",
                originalGravity: "",
                acidBlendTanninAdditions: "",
                yeastNutrientsEnergizers: "",
                optionalFlavorAdditions: "",
                sanitationEquipment: "",
            },
            primaryfermentation: {
                yeastStrainPitchRate: "",
                temperatureRange: "",
                gravityChecks: "",
                pHChecks: "",
                stirringDegassing: "",
                nutrientSchedule: "",
                primaryFermentationDuration: "",
            },
            secondaryfermentation: {
                rackingOffLees: "",
                fruitOrSpiceAdditions: "",
                clarityStabilization: "",
                agingVessel: "",
                lengthOfBulkAging: "",
                periodicGravityTasteTests: "",
            },
            packaging: {
                packagingDate: "",
                stabilization: "",
                bottlingMethod: "",
                finalGravity: "",
                abvCalculation: "",
                labelingNotes: "",
            },
            tastingandfinalnotes: {
                appearance: "",
                aroma: "",
                flavorBalance: "",
                mouthfeel: "",
                overallImpression: "",
                agingProgress: "",
            },
        },
    },

    kombucha: {
        streamlined: {
            recipeandpreparation: {
                teaTypeQuantity: "",
                sugarAmount: "",
                scobySource: "",
            },
            primaryfermentation: {
                fermentationTemperature: "",
                tasteCheck: "",
            },
            secondaryfermentation: {
                flavorAdditions: "",
            },
            packaging: {
                packagingMethod: "",
                finalPhCheck: "",
            },
            tastingandfinalnotes: {
                appearance: "",
                flavor: "",
                carbonationLevel: "",
            },
        },

        advanced: {
            recipeandpreparation: {
                teaTypeQuantity: "",
                waterQuality: "",
                sugarSourceAmount: "",
                scobySourceCondition: "",
                starterTeaAmountPhLevel: "",
                initialVolume: "",
                sanitationPreparation: "",
            },
            primaryfermentation: {
                initialPh: "",
                fermentationTemperature: "",
                timeToDesiredPhFlavor: "",
                scobyHealthChecks: "",
                tasteTestsDuringFermentation: "",
                fermenterVessel: "",
            },
            secondaryfermentation: {
                flavorAdditions: "",
                bottlingForNaturalCarbonation: "",
                secondaryFermentDuration: "",
                temperatureFor2F: "",
                burpingBottles: "",
            },
            packaging: {
                packagingMethod: "",
                bottleConditionChecks: "",
                finalPhSweetnessLevel: "",
                refrigerationDate: "",
                scobyStorage: "",
            },
            tastingandfinalnotes: {
                appearance: "",
                aroma: "",
                flavor: "",
                carbonationLevel: "",
                mouthfeel: "",
                overallImpressionNextBatch: "",
            },
        },
    },

    soda: {
        streamlined: {
            recipeandpreparation: {
                flavorBase: "",
                sweetener: "",
                waterVolume: "",
            },
            primaryfermentation: {
                // Some sodas don't ferment at all; keep minimal
                yeastIfAny: "",
            },
            packaging: {
                packagingMethod: "",
                carbonationMethod: "",
            },
            tastingandfinalnotes: {
                appearance: "",
                flavor: "",
                carbonationLevel: "",
            },
        },

        advanced: {
            recipeandpreparation: {
                flavorBaseSyrup: "",
                sweetenerTypeAmount: "",
                acidSource: "",
                waterVolume: "",
                sanitationIfFermenting: "",
                optionalYeast: "",
            },
            primaryfermentation: {
                yeastAddition: "",
                fermentationTemperature: "",
                duration: "",
                gasCheckBottlePressure: "",
                sweetnessChecks: "",
            },
            secondaryfermentation: {
                clarification: "",
                flavorAdjustments: "",
                aging: "",
            },
            packaging: {
                packagingMethod: "",
                carbonationMethod: "",
                finalSweetnessCheck: "",
                safetyCheck: "",
            },
            tastingandfinalnotes: {
                appearance: "",
                aroma: "",
                flavor: "",
                carbonationLevel: "",
                overallImpression: "",
            },
        },
    },
};

export default noteSchemas;
