import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import Footer from "../components/Footer";
import Nav from "../components/Navbar";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createBrew, getAllMyBrews, deleteBrew, updateBrew } from "../services/api-helper";
import DEFAULT_IMAGE_URL from "../assets/Images/defaultimage.jpg"

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": { borderColor: theme.palette.grey[400] },
    "&:hover fieldset": { borderColor: "#007bff" },
    "&.Mui-focused fieldset": { borderColor: "#FF6F61" },
  },
  "& .MuiInputLabel-root": { "&.Mui-focused": { color: "#FF6F61" } },
}));

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const BrewCollection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [allBrews, setAllBrews] = useState([]);
  const [selectedBrew, setSelectedBrew] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Global loading state for fetching brews
  const [actionLoading, setActionLoading] = useState(false); // Loading for create/delete/update actions
  const [brewName, setBrewName] = useState("");
  const [brewImage, setBrewImage] = useState(null);
  const [currentImagePreview, setCurrentImagePreview] = useState("");
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [published, setPublished] = useState(false);
  const [description, setDescription] = useState("");
  const [brewType, setBrewType] = useState("");
  const [brewStyle, setBrewStyle] = useState("");
  const [alert, setAlert] = useState(null);

  const clearFields = () => {
    setBrewImage(null);
    setBrewName("");
    setDescription("");
    setSelectedDate(dayjs());
    setBrewType("");
    setBrewStyle("");
    setPublished(false);
  }
  const fetchBrews = async () => {
    setLoading(true);
    try {
      const response = await getAllMyBrews();
      const updatedBrews = response.map((brew) => ({
        ...brew,
        imageUrl: brew.imageUrl || DEFAULT_IMAGE_URL,
      }));
      setAllBrews(updatedBrews);
    } catch (error) {
      setAlert({
        type: "error",
        message: "Failed to fetch your Brews. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };
  const handleEditBrew = (brew) => {
    setSelectedBrew(brew);
    setBrewName(brew.brewName || "");
    setBrewType(brew.brewType || "");
    setDescription(brew.description || "");
    setSelectedDate(brew.date ? dayjs(brew.date, "MM/DD/YYYY") : dayjs());
    setBrewType(brew.brewType || "");
    setBrewStyle(brew.brewStyle || "");
    setPublished(brew.published || false);
    setCurrentImagePreview(brew.imageUrl || DEFAULT_IMAGE_URL); // Ensure existing image is shown
    setIsEditModalOpen(true);
  };

  const handleUpdateBrew = async () => {
    if (!brewName) {
      alert("Brew name is required.");
      return;
    }

    const formattedDate = selectedDate ? selectedDate.format("MM/DD/YYYY") : null;

    try {
      let imageBase64 = null;

      if (brewImage) {
        const reader = new FileReader();
        reader.onload = async () => {
          imageBase64 = reader.result.split(",")[1];
          await saveUpdate(imageBase64);
        };
        reader.readAsDataURL(brewImage);
      } else {
        await saveUpdate(imageBase64);
      }
    } catch (error) {
      setAlert({
        type: "error",
        message: "Failed to Upload image. Please try again.",
      });
    }
  };

  const saveUpdate = async (imageBase64) => {
    try {
      await updateBrew(selectedBrew.BrewId, {
        brewName,
        description,
        imageBase64,
        imageType: brewImage?.type || null,
        brewType,
        brewStyle,
        published,
        date: selectedDate ? selectedDate.format("MM/DD/YYYY") : null,
      });
      fetchBrews();
      setIsEditModalOpen(false);
      clearFields();
      setAlert({ type: "success", message: "Brew updated successfully!" });
    } catch (error) {
      setAlert({
        type: "error",
        message: "Failed to update Brew. Please try again.",
      });
    }
  };

  useEffect(() => {
    fetchBrews();
  }, []);

  const handleAddBrew = async () => {
    if (!brewName) {
      alert("Brew name is required.");
      return;
    }

    // Format the selected date
    const formattedDate = selectedDate ? selectedDate.format('MM/DD/YYYY') : null;

    const reader = new FileReader();
    reader.onload = async () => {
      const imageBase64 = brewImage ? reader.result.split(",")[1] : null;
      try {
        await createBrew({
          brewName,
          description: "",
          imageBase64,
          brewType,
          imageType: brewImage?.type || null,
          date: formattedDate, // Add the formatted date
        });
        clearFields();
        fetchBrews();
        setIsModalOpen(false);
        setAlert({ type: "success", message: "Successfully created new Brew!" });
      } catch (error) {
        setAlert({
          type: "error",
          message: "Failed to create new Brew. Please try again.",
        });
      }
    };

    if (brewImage) {
      reader.readAsDataURL(brewImage);
    } else {
      try {
        await createBrew({
          brewName,
          description: "",
          brewType,
          date: formattedDate, // Add the formatted date
        });
        clearFields();
        fetchBrews();
        setIsModalOpen(false);
        setAlert({ type: "success", message: "Brew created uccessfully!" });
      } catch (error) {
        setAlert({
          type: "error",
          message: "Failed to Upload image. Please try again.",
        });
      }
    }
  };

  const handleDeleteBrew = async (brewId) => {
    try {
      await deleteBrew(brewId);
      fetchBrews();
      setAlert({ type: "success", message: "Brew deleted successfully!" });
    } catch (error) {
      setAlert({
        type: "error",
        message: "Failed to delete brew. Please try again.",
      });
    }
  };

  useEffect(() => {
    fetchBrews();
  }, []);

  return (
    <>
      <Nav />
      <Box
        sx={{
          minHeight: "calc(100vh - 150px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: allBrews.length === 0 ? "center" : "flex-start",
          px: { xs: "1rem", sm: "2rem", md: "4rem", lg: "6rem" },
          backgroundColor: "#F8F9FA",
        }}
      >
        {alert && (
          <Alert
            severity={alert.type}
            onClose={() => setAlert(null)}
            sx={{ mb: 3, maxWidth: "600px", mx: "auto" }}
          >
            {alert.message}
          </Alert>
        )}
        <Grid container>
          <Grid item xs={12} textAlign="center" my={3}>
            <Typography variant="h2">My Brew Collection</Typography>
            <Typography variant="body1" color="gray">
              Track, edit, and showcase your unique creations.
            </Typography>
          </Grid>
        </Grid>

        {allBrews.length === 0 ? (
          <Grid container justifyContent="center" my={3}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              sx={{ backgroundColor: "#031D2D" }}
              onClick={() => setIsModalOpen(true)}
            >
              Add New Brew
            </Button>
          </Grid>
        ) : (
          <Grid container justifyContent="flex-end" mb={2}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              sx={{ backgroundColor: "#031D2D" }}
              onClick={() => setIsModalOpen(true)}
            >
              Add New Brew
            </Button>
          </Grid>
        )}

        <Grid container spacing={2}>
          {allBrews.map((brew, index) => (
            <Grid item xs={12} sm={6} md={4} key={brew.brewId || `index-${index}`}>
              <Box
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "1rem",
                  p: 2,
                  textAlign: "center",
                  boxShadow:
                    hoveredIndex === index ? "0px 4px 10px rgba(0, 0, 0, 0.1)" : "none",
                }}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <Typography variant="h6">{brew.brewName}</Typography>
                <img
                  src={brew.imageUrl}
                  alt={brew.brewName}
                  style={{
                    width: "100%",
                    height: "200px",
                    borderRadius: "1rem",
                    objectFit: "contain",
                    marginTop: "1rem",
                  }}
                />
                <Box mt={2}>
                  <IconButton onClick={() => handleEditBrew(brew)} color="primary">
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteBrew(brew.BrewId)} color="error">
                    <DeleteIcon />
                  </IconButton>
                </Box>
                <Button
                  variant="contained"
                  sx={{ mt: 2, backgroundColor: "#FF6F61", color: "#fff" }}
                  onClick={() => {
                    window.location.href = `/notes?brewId=${brew.BrewId}`;
                  }}
                >
                  View Notes
                </Button>
              </Box>
            </Grid>
          ))}
        </Grid>

        <Modal
          open={isEditModalOpen}
          onClose={() => {
            setIsEditModalOpen(false);
            clearFields();
          }}
          aria-labelledby="edit-modal-title"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: { xs: "90%", sm: "70%", md: "50%" },
              maxHeight: "90vh", // Prevent overflow
              padding: 2,
              backgroundColor: "#fff",
              borderRadius: "1rem", // Ensure rounded corners
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              display: "flex",
              flexDirection: "column",
              overflow: "auto", // Add scrolling within the modal
              scrollbarWidth: "thin", // For Firefox
              scrollbarColor: "#FF6F61 #f0f0f0", // Colors for Firefox
              "&::-webkit-scrollbar": {
                width: "8px", // Width of the scrollbar
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#f0f0f0", // Track color
                borderRadius: "10px", // Rounded corners for the track
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#FF6F61", // Thumb color
                borderRadius: "10px", // Rounded thumb
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#FF3F41", // Hover state for the scrollbar thumb
              },
            }}
          >
            <Typography
              variant="h5"
              sx={{ color: "#FF6F61", mb: 2, textAlign: "center" }}
            >
              Edit Brew
            </Typography>

            {/* Content Section */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                columnGap: 2,
                rowGap: 1,
              }}
            >
              <StyledTextField
                label="Brew Name"
                value={brewName}
                onChange={(e) => setBrewName(e.target.value)}
                fullWidth
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date"
                  value={selectedDate}
                  onChange={(newValue) => setSelectedDate(newValue)}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </LocalizationProvider>
              <TextField
                select
                label="Brew Type"
                value={brewType}
                onChange={(e) => {
                  setBrewType(e.target.value)
                }
                }
                fullWidth
                SelectProps={{
                  native: true,
                }}
                required
              >
                <option value=""></option>
                <option value="mead">Mead</option>
                <option value="wine">Wine</option>
                <option value="beer">Beer</option>
                <option value="kombucha">Kombucha</option>
                <option value="soda">Soda</option>
              </TextField>
              <StyledTextField
                label="Brew Style"
                value={brewStyle}
                onChange={(e) => setBrewStyle(e.target.value)}
                fullWidth
              />
            </Box>

            {/* Description Field */}
            <StyledTextField
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
              multiline
              rows={2}
              sx={{ mt: 2 }}
            />

            {/* Image Upload Section */}
            <Box sx={{ mt: 2, textAlign: "center" }}>
              <Button
                variant="contained"
                component="label"
                fullWidth
                sx={{
                  backgroundColor: "#FF6F61",
                  mb: 1,
                }}
              >
                Upload New Image
                <VisuallyHiddenInput
                  type="file"
                  accept="image/jpeg, image/png"
                  onChange={(e) => {
                    setBrewImage(e.target.files[0]);
                    setCurrentImagePreview(URL.createObjectURL(e.target.files[0]));
                  }}
                />
              </Button>
              {currentImagePreview && (
                <Box>
                  <Typography variant="body2">Image Preview:</Typography>
                  <img
                    src={currentImagePreview}
                    alt="Preview"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100px",
                      objectFit: "contain",
                      borderRadius: "0.5rem",
                      border: "1px solid #ccc",
                      marginTop: "0.5rem",
                    }}
                  />
                </Box>
              )}
            </Box>

            {/* Footer */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 3,
                flexDirection: { xs: "column", md: "row" }, // Responsive design
                gap: 2, // Adjust spacing between elements
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={published}
                    onChange={(e) => setPublished(e.target.checked)}
                  />
                }
                label="Publish"
                sx={{ marginRight: { xs: 0, md: 2 } }} // Adjust margin
              />
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    setIsEditModalOpen(false);
                    clearFields();
                  }}
                  sx={{
                    backgroundColor: "#D6D6D6",
                    color: "#000",
                    fontSize: "0.9rem",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleUpdateBrew}
                  sx={{
                    backgroundColor: "#FF6F61",
                    color: "#fff",
                    fontSize: "0.9rem",
                  }}
                >
                  Save Changes
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 2, // Padding around the modal
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", sm: "80%", md: "50%" },
              maxHeight: "90vh", // Ensures content stays within the viewport
              overflow: "auto", // Enables scrolling for overflow
              backgroundColor: "#fff",
              borderRadius: "1rem",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              p: 3, // Padding inside the modal
              display: "flex",
              flexDirection: "column",
            }}
          >
            <form>
              <Typography variant="h5" textAlign="center" gutterBottom>
                Add New Brew
              </Typography>

              {/* First Row - Brew Name */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 2,
                  my: 2,
                }}
              >
                <StyledTextField
                  label="Brew Name"
                  fullWidth
                  variant="outlined"
                  value={brewName}
                  onChange={(e) => setBrewName(e.target.value)}
                  required
                />
              </Box>

              {/* Second Row - Brew Type and Date */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 2,
                  my: 2,
                }}
              >
                <TextField
                  select
                  label="Brew Type"
                  value={brewType}
                  onChange={(e) => {
                    setBrewType(e.target.value)
                  }
                  }
                  fullWidth
                  SelectProps={{
                    native: true,
                  }}
                  required
                >
                  <option value=""></option>
                  <option value="mead">Mead</option>
                  <option value="wine">Wine</option>
                  <option value="beer">Beer</option>
                  <option value="kombucha">Kombucha</option>
                  <option value="soda">Soda</option>
                </TextField>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Select a Date"
                    value={selectedDate}
                    onChange={(newValue) => setSelectedDate(newValue)}
                    slotProps={{ textField: { fullWidth: true } }}
                    required
                  />
                </LocalizationProvider>
              </Box>

              {/* Image Upload Section */}
              <Button
                variant="contained"
                component="label"
                fullWidth
                sx={{ backgroundColor: "#FF6F61", mb: 2 }}
              >
                Upload Image
                <VisuallyHiddenInput
                  type="file"
                  accept="image/jpeg, image/png"
                  onChange={(e) => setBrewImage(e.target.files[0])}
                />
              </Button>
              {brewImage && (
                <Box mt={2} textAlign="center">
                  <Typography variant="body2">Image Preview:</Typography>
                  <img
                    src={URL.createObjectURL(brewImage)}
                    alt="Preview"
                    style={{
                      maxWidth: "100%",
                      height: "200px",
                      objectFit: "contain",
                      borderRadius: "0.5rem",
                      border: "1px solid #ccc",
                    }}
                  />
                </Box>
              )}

              {/* Footer Actions */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: 2,
                  mt: 3,
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    clearFields();
                    setIsModalOpen(false);
                  }}
                  sx={{ backgroundColor: "#D6D6D6", color: "#000" }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleAddBrew}
                  sx={{ backgroundColor: "#FF6F61", color: "#fff" }}
                >
                  Save Brew
                </Button>
              </Box>
            </form>
          </Box>
        </Modal>
      </Box>
      <Footer />
    </>
  );
};

export default BrewCollection;
