import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../services/Urls";
import { CognitoUser } from "amazon-cognito-identity-js";
import {
  authenticateUser,
  signUpUser,
  sendResetPasswordLink,
  userPool
} from "../../services/auth-utils";

export const UserAuth = createApi({
  reducerPath: "userAuth",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  prepareHeaders: (headers) => {
    const session = JSON.parse(localStorage.getItem("userSession") || "{}");
    const token = session?.token;

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    headers.set("Content-Type", "application/json");
    return headers;
  },
  endpoints: (builder) => ({
    SignInUser: builder.mutation({
      queryFn: async ({ email, password }, _queryApi, _extraOptions, _baseQuery) => {
        try {
          const result = await authenticateUser(email, password);
          return { data: { message: "Sign-in successful", ...result } };
        } catch (error) {
          console.error('Cognito error:', error);
          return {
            error: {
              status: "CUSTOM_ERROR",
              data: error.message,
              code: error.code
            }
          };
        }
      },
    }),

    SignUpUser: builder.mutation({
      queryFn: async ({ email, password, attributes }, _queryApi, _extraOptions, _baseQuery) => {
        try {
          await signUpUser(email, password, attributes);
          return { data: { message: "Sign-up successful. Please verify your email." } };
        } catch (error) {
          return { error: { status: "CUSTOM_ERROR", data: error.message } };
        }
      },
    }),

    ForgetPassword: builder.mutation({
      queryFn: async ({ email }) => {
        try {
          await sendResetPasswordLink(email);
          return { data: { message: "Reset password code sent to your email." } };
        } catch (error) {
          return { error: { status: "CUSTOM_ERROR", data: error.message } };
        }
      },
    }),

    VerifyOtp: builder.mutation({
      queryFn: async ({ email, code }) => {
        return new Promise((resolve) => {
          const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool
          });

          cognitoUser.verifyAttribute('email', code, {
            onSuccess: (result) => {
              resolve({ data: { message: "Code verified successfully." } });
            },
            onFailure: (err) => {
              console.error('Verification error:', err);
              resolve({
                error: {
                  status: "CUSTOM_ERROR",
                  data: err.message || "Verification failed"
                }
              });
            }
          });
        });
      },
    }),

    ResetPassword: builder.mutation({
      queryFn: async ({ email, verificationCode, newPassword }) => {
        return new Promise((resolve, reject) => {
          const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool
          });

          cognitoUser.confirmPassword(verificationCode, newPassword, {
            onSuccess() {
              resolve({ data: { message: "Password reset successful." } });
            },
            onFailure(err) {
              resolve({ error: { status: "CUSTOM_ERROR", data: err.message } });
            }
          });
        });
      },
    }),

    AddNote: builder.mutation({
      query: (body) => ({
        url: "/addNotes",
        method: "POST",
        body,
      }),
    }),

    DeleteNote: builder.mutation({
      query: (id) => ({
        url: `/deleteNotes/${id}`,
        method: "DELETE",
      }),
    }),

    updateNote: builder.mutation({
      query: ({ id, updatedData }) => ({
        url: `/updateNotes/${id}`,
        method: "PUT",
        body: updatedData,
      }),
    }),

    GetNote: builder.mutation({
      query: (body) => ({
        url: "/getNotes",
        method: "GET",
        body,
      }),
    }),

    AddBrews: builder.mutation({
      query: (body) => ({
        url: "/brews",
        method: "POST",
        body,
      }),
    }),

    DeleteBrews: builder.mutation({
      query: (id) => ({
        url: `/brews/${id}`,
        method: "DELETE",
      }),
    }),

    GetAllBrews: builder.mutation({
      query: (body) => ({
        url: "/brews",
        method: "GET",
        body,
      }),
    }),

    GetBrew: builder.mutation({
      query: (slug) => ({
        url: `/brews/${slug}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useSignInUserMutation,
  useSignUpUserMutation,
  useForgetPasswordMutation,
  useVerifyOtpMutation,
  useResetPasswordMutation,
  useAddNoteMutation,
  useDeleteNoteMutation,
  useUpdateNoteMutation,
  useGetNoteMutation,
  useAddBrewsMutation,
  useDeleteBrewsMutation,
  useGetAllBrewsMutation,
  useGetBrewMutation,
} = UserAuth;
