import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import Home from "./Screens/Home";
import SignIn from "./Screens/SignIn";
import SignUp from "./Screens/signUp";
import SignUpSuccess from "./Screens/SignUpSuccess";
import BrewCollection from "./Screens/BrewCollection";
import ExploreBrews from "./Screens/ExploreBrews";
import ForgetPassword from "./Screens/forgetPassword";
import BrewNotes from "./Screens/BrewNotes";
// import Otp from "./Screens/Otp";
// import ResetPassword from "./Screens/ResetPassword";
// import ResetSuccessful from "./Screens/ResetSuccessful";
import Settings from "./Screens/Settings";
import ShareableBrew from "./Screens/shareableBrew";
// import ProtectedRoute from "./components/ProtectedRoute";
import { SessionProvider } from "./Providers/SessionProvider";
import { store } from './redux/store'; // Ensure this path is correct

function App() {
    useEffect(() => {
        // On app load, check and log the current session
        const session = JSON.parse(localStorage.getItem("userSession") || "{}");
        if (!session?.token) {
            console.warn("No valid session found in localStorage. User might need to sign in.");
        }
    }, []);

    return (
        <Provider store={store}>

            <Router
                future={{
                    v7_startTransition: true,
                    v7_relativeSplatPath: true,
                }}>
                <SessionProvider>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/signin" element={<SignIn />} />
                        <Route path="/signup" element={<SignUp />} />
                        <Route path="/signupsuccess" element={<SignUpSuccess />} />
                        <Route path="/mybrews" element={<BrewCollection />} />
                        <Route path="/explore-brews" element={<ExploreBrews />} />
                        <Route path="/forgot-password" element={<ForgetPassword />} />
                        <Route path="/notes" element={<BrewNotes />} />
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/shareable-brew" element={<ShareableBrew />} />
                    </Routes>
                </SessionProvider>
            </Router>
        </Provider>
    );
}

export default App;
