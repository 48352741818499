import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { checkSession, refreshSession } from '../services/auth-utils';
import { setSession, clearSession } from '../redux/slices/notesSlices/AuthSlice';
import { useNavigate } from 'react-router-dom';
import { Modal, Box, Button, Typography } from '@mui/material';

export const SessionProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    let refreshTimeout;

    const scheduleTokenRefresh = (expiresIn) => {
      if (refreshTimeout) clearTimeout(refreshTimeout);
      // Schedule refresh a little before token expiration
      refreshTimeout = setTimeout(() => refreshTokens(), Math.max(expiresIn - 60000, 0));
    };

    const refreshTokens = async () => {
      try {
        const session = await refreshSession();
        if (session) {
          const tokenExpiration = session.userInfo.exp * 1000;
          const now = Date.now();
          const expiresIn = tokenExpiration - now;

          dispatch(setSession({
            isAuthenticated: true,
            token: session.token,
            userInfo: session.userInfo,
          }));
          scheduleTokenRefresh(expiresIn);
        } else {
          handleSessionInvalid();
        }
      } catch (error) {
        console.error('Failed to refresh session:', error);
        handleSessionInvalid();
      }
    };

    const handleSessionInvalid = () => {
      dispatch(clearSession());
      setIsModalOpen(true);
    };

    const initSession = async () => {
      try {
        const session = await checkSession();
        if (session) {
          const tokenExpiration = session.userInfo.exp * 1000;
          const now = Date.now();
          const expiresIn = tokenExpiration - now;

          dispatch(setSession({
            isAuthenticated: true,
            token: session.token,
            userInfo: session.userInfo,
          }));
          scheduleTokenRefresh(expiresIn);
        } else {
          handleSessionInvalid();
        }
      } catch (error) {
        console.error('Session initialization failed:', error);
        handleSessionInvalid();
      }
    };

    initSession();

    return () => {
      if (refreshTimeout) clearTimeout(refreshTimeout);
    };
  }, [dispatch]);

  return (
    <>
      {children}
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="session-invalid-modal-title"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: { xs: '90%', sm: '70%', md: '50%' },
            padding: 2,
            backgroundColor: '#fff',
            borderRadius: '1rem',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            textAlign: 'center',
          }}
        >
          <Typography
            variant="h6"
            sx={{ color: '#FF6F61', mb: 2 }}
          >
            Session Invalid
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: '#555', mb: 3 }}
          >
            Please sign in to continue.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 2,
            }}
          >
            <Button
              variant="contained"
              sx={{ backgroundColor: '#FF6F61', color: '#fff' }}
              onClick={() => {
                setIsModalOpen(false);
                navigate('/');
              }
              }
            >
              Home
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: '#FF6F61', color: '#fff' }}
              onClick={() => {
                setIsModalOpen(false);
                navigate('/signin')
              }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
