import { Box, Button, InputBase, Typography, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import coverImage from "../assets/Images/forgetPasswordImg.png";
import Nav from "../components/Navbar";
import { useForgetPasswordMutation, useVerifyOtpMutation, useResetPasswordMutation } from "../redux/apis/UserAuth";
import { useNavigate } from "react-router-dom";

const ForgetPassword = () => {
  const [step, setStep] = useState(1); // Tracks the current step in the workflow
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [ForgetPassword] = useForgetPasswordMutation();
  const [VerifyOtp] = useVerifyOtpMutation();
  const [ResetPassword] = useResetPasswordMutation();

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (!/\d/.test(value) && value !== "") return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < 5) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
  };

  const handlePasteOtp = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text").replace(/\D/g, '').slice(0, 6);
    const newOtp = [...otp];
    for (let i = 0; i < pasteData.length; i++) {
      newOtp[i] = pasteData[i];
    }
    setOtp(newOtp);
  };

  const handleForgetPassword = async () => {
    setError(null);

    try {
      const result = await ForgetPassword({ email });
      if (result?.data) {
        setStep(2);
      }
    } catch (err) {
      setError(err?.data || "An error occurred while sending the reset link.");
    }
  };

  const handleOtpVerification = async () => {
    setError(null);
    const otpCode = otp.join('');

    try {
      const result = await VerifyOtp({ email, code: otpCode });
      if (result?.data) {
        setStep(3);
      }
    } catch (err) {
      setError(err?.data || "Invalid OTP.");
    }
  };

  const handleResetPassword = async () => {
    setError(null);

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      const result = await ResetPassword({ email, newPassword: password });

      if (result?.data) {
        setStep(4);
      }
    } catch (err) {
      setError(err?.data || "An error occurred while resetting the password.");
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <Typography variant="h5" sx={{ fontWeight: "700", mb: 2 }}>
              Forgot Password?
            </Typography>
            <Typography variant="body1" sx={{ mb: 3, textAlign: "center" }}>
              Enter your email, and we’ll send you a reset link.
            </Typography>
            <TextField
              fullWidth
              size="small"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              placeholder="Enter your email"
              sx={{ width: "80%", mb: 2 }}
            />
            {error && (
              <Typography color="error" sx={{ mb: 2 }}>
                {error}
              </Typography>
            )}
            <Button
              onClick={handleForgetPassword}
              variant="contained"
              sx={{
                width: "50%",
                backgroundColor: "#FF6F61",
                px: 4,
                py: 1.5,
                borderRadius: 6,
              }}
            >
              Send Reset Link
            </Button>
          </>
        );
      case 2:
        return (
          <>
            <Typography variant="h5" sx={{ fontWeight: "700", mb: 2 }}>
              Check Your Email
            </Typography>
            <Typography variant="body1" sx={{ mb: 3, textAlign: "center" }}>
              Please enter the OTP sent to your email.
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "center", gap: 1, mb: 2 }}
              onPaste={handlePasteOtp}
            >
              {otp.map((digit, index) => (
                <InputBase
                  key={index}
                  id={`otp-${index}`}
                  value={digit}
                  onChange={(e) => handleOtpChange(e, index)}
                  inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                  sx={{
                    width: "2.5rem",
                    height: "2.5rem",
                    fontSize: "1.5rem",
                    border: "1px solid #bbb",
                    borderRadius: "4px",
                    backgroundColor: "#fff",
                  }}
                />
              ))}
            </Box>
            {error && (
              <Typography color="error" sx={{ mb: 2 }}>
                {error}
              </Typography>
            )}
            <Button
              onClick={handleOtpVerification}
              variant="contained"
              sx={{
                width: "50%",
                backgroundColor: "#FF6F61",
                px: 4,
                py: 1.5,
                borderRadius: 6,
              }}
            >
              Verify OTP
            </Button>
          </>
        );
      case 3:
        return (
          <>
            <Typography variant="h5" sx={{ fontWeight: "700", mb: 2 }}>
              Reset Password
            </Typography>
            <Typography variant="body1" sx={{ mb: 3, textAlign: "center" }}>
              Enter your new password below.
            </Typography>
            <TextField
              fullWidth
              type="password"
              size="small"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              placeholder="New Password"
              sx={{ width: "80%", mb: 2 }}
            />
            <TextField
              fullWidth
              type="password"
              size="small"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              placeholder="Confirm Password"
              sx={{ width: "80%", mb: 2 }}
            />
            {error && (
              <Typography color="error" sx={{ mb: 2 }}>
                {error}
              </Typography>
            )}
            <Button
              onClick={handleResetPassword}
              variant="contained"
              sx={{
                width: "50%",
                backgroundColor: "#FF6F61",
                px: 4,
                py: 1.5,
                borderRadius: 6,
              }}
            >
              Reset Password
            </Button>
          </>
        );
      case 4:
        return (
          <>
            <Typography variant="h5" sx={{ fontWeight: "700", mb: 2 }}>
              Password Reset Successful
            </Typography>
            <Typography variant="body1" sx={{ mb: 3, textAlign: "center" }}>
              Your password has been successfully reset. You can now log in.
            </Typography>
            <Button
              onClick={() => navigate("/SignIn")}
              variant="contained"
              sx={{
                width: "50%",
                backgroundColor: "#FF6F61",
                px: 4,
                py: 1.5,
                borderRadius: 6,
              }}
            >
              Go to Login
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Nav />
      <Grid
        container
        sx={{ height: "100vh", overflow: { xs: "auto", sm: "auto", md: "hidden" } }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <img
            src={coverImage}
            alt="Forgot Password Illustration"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: 2,
            height: "100%",
            overflowY: "auto",
          }}
        >
          {renderStep()}
        </Grid>
      </Grid>
    </>
  );
};

export default ForgetPassword;
